<template>
    <div>
        <el-card shadow="never">
            <template #header>
                <div class="clearfix" style="text-align:left">
                    <span>保障服务列表</span>
                </div>
            </template>
            <div class="content">
                <div class="content-header">
                    <el-row :gutter="5">
                        <el-col :span="8"   style="text-align:left;">
                            <div class="searchblock">
                                <!--<el-button type="primary" size="small" @click="SelectMerchant" style="margin-right:10px;"  v-if="ismerchant != 1">选择商户</el-button>-->
                                <el-select v-model="status" style="margin-right:10px;width:250px" size="small">
                                    <el-option label="全部" value="0"></el-option>
                                    <el-option label="启用" value="1"></el-option>
                                    <el-option label="停用" value="2"></el-option>
                                </el-select>
                                <el-input v-model="keyword" placeholder="保障服务名称" prefix-icon="el-icon-search" class="hidden-sm-and-down" size="small"></el-input>
                                <el-button type="primary" @click="init" style="margin-left:10px;" class="hidden-sm-and-down" size="small">搜索</el-button>
                                <el-button type="success" @click="add" size="small">添加</el-button>
                            </div>
                        </el-col>
                    </el-row>
                </div>
            </div>
            <div class="content-main">
                <el-table :data="tabledata" border style="width: 100%; margin: 1em 0;"
                                v-loading="load"
                                size="small"
                                element-loading-spinner="el-icon-loading" 
                                element-loading-text="加载中..."
                                :default-sort = "{prop: 'AddDTime', order: 'descending'}">
                    <!--<el-table-column label="所属商户" prop="Merchant" >
                        <template #default="scope">
                            <span v-if="scope.row.Merchant">{{scope.row.Merchant}}</span>
                            <span v-else>深圳市闲牛科技有限公司</span>
                        </template>
                    </el-table-column>-->
                    <el-table-column label="保障服务名称" width="200" prop="GuarantTitle"></el-table-column>
                    <el-table-column label="描述" min-width="200" prop="GuarantDesc" :show-overflow-tooltip='true'></el-table-column>
                    <el-table-column label="创建时间" width="200" prop="AddDTime" :formatter="Getdate" sortable></el-table-column>
                    <el-table-column label="更新时间" width="200" prop="UpdateDTime" :formatter="Getdate" sortable></el-table-column>
                    <el-table-column label="状态">
                        <template #default="scope">
                            <span v-if="scope.row.Status == 0"><span style="color:red">停用</span></span>
                            <span v-if="scope.row.Status == 1">启用</span>
                        </template>
                    </el-table-column>
                    <el-table-column label="操作" prop="option">
                        <template #default="scope">
                            <el-button v-if="scope.row.Status == 0" type="primary"  @click="Enable(scope.row.ID)" style="margin-right:10px;" size="small">启用</el-button>
                            <el-button v-if="scope.row.Status == 1" type="primary"  @click="Disable(scope.row.ID)" style="margin-right:10px;" size="small">停用</el-button>
                            <el-button type="primary"  @click="Edit(scope.row.ID)" :disabled="scope.row.Status == 0?false:true" style="margin-right:10px;" size="small">
                                编辑</el-button>
                            <el-button type="primary" @click="AddDetail(scope.row.ID)" tyle="margin-right:10px;">添加保障产品</el-button>
                            <el-button type="primary"   @click="Del(scope.row.ID)" :disabled="scope.row.Status == 0?false:true" size="small">删除</el-button>
                            
                        </template>
                    </el-table-column>
                </el-table>
                <el-pagination @current-change="handleCurrentChange"
                                @size-change="handleSizeChange"
                                :current-page="curpage"
                                :page-size="pagesize"
                                :page-sizes="[10,20,30,50,100]"
                                layout="sizes,total, prev, pager, next, jumper"
                                :total="totalcount"
                                :hide-on-single-page="true"
                                style="text-align:center"></el-pagination>
            </div>
        </el-card>
    </div>
    <el-dialog v-model="editor" title="编辑保障服务信息" width="50%">
        <el-form :model="guarantinfo" label-position="left" :rules="rule" ref="guarantinfo">
            <el-form-item label="名称" label-width="100px" prop="guarantname">
                <el-input v-model="guarantinfo.guarantname" size="small"></el-input>
            </el-form-item>
            <el-form-item label="描述"  label-width="100px">
                <el-input type="textarea" rows="4" v-model="guarantinfo.guarantdesc" size="small"></el-input>
            </el-form-item>
        </el-form>
        <template #footer>
            <span class="dialog-footer">
            <el-button @click="CloseDialog('guarantinfo')" size="small">取 消</el-button>
            <el-button type="primary" @click="submitform('guarantinfo')" size="small">确 定</el-button>
            </span>
        </template>
    </el-dialog>
    <!--<el-dialog v-model="merchant" title="选择商户" center>
        <div class="merchant-block">
            <el-transfer v-model="selmerchant"
                filterable
                filter-placeholder="请输入商户名称"
                :data='merchantlist'
                :titles='transfertitle'>
            </el-transfer>
            <div class="bottom">
                <el-button type="primary" size="small" @click="ConfirmMerchant">确定</el-button>
                <el-button size="small" @click="Back">取消</el-button>
            </div>
        </div>
    </el-dialog>-->
</template>
<style scoped>
.searchblock{
    display:flex;
    flex-direction: row;
    flex-wrap: nowrap;
}
.merchant-block {
    display:flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.merchant-block .bottom {
    margin-top: 20px;
    display:flex;
    justify-content: center;
    align-items: center;
}
</style>
<script>
import Cookies from 'js-cookie'
import qs from 'qs'
import constant from "@/constant"
import { ElMessage } from 'element-plus'
export default {
    data(){
        return {
            option:[],
            brands:[],
            
            selmerchant:[],
            transfertitle:['商户列表','已选商户'],
            merchantlist:[],
            merchant:false,
            keyword:'',
            status:'0',
            tabledata:[],
            statuslabel:'停用',
            editor: false,
            guarantinfo:{
                guarantname:'',
                guarantdesc:'',
                id:'',
                status: '0',
                rules:[
                    {
                        cateid:[],
                        brandid:'',
                        modelid:'',
                        price:0,
                        models:[],
                    }
                ]
            },
            rule:{
                guarantname:[{
                    required: true, message: '请输入保障服务名称',trigger: 'blur'
                }]
            },
            curpage:1,
            pagesize:10,
            totalcount:'',
        }
    },
    methods:{
        AddDetail(id){
            this.$router.push({path:'/addguarant',query:{id:id}});
        },
        
        ConfirmMerchant(){
            console.log(this.selmerchant);
            this.merchant = false;
        },
        Back(){
            this.merchant = false;
        },
        SelectMerchant(){
            this.merchant = true;
        },
        handleSizeChange(val){
            console.log(val);
            this.pagesize = val;
            this.init();
        },
        handleCurrentChange(val){
            console.log(val);
            this.curpage = val;
            this.init();
        },
        check(e){
            console.log(e);
        },
        CloseDialog(formName){
            this.editor = false;
            this.$refs[formName].resetFields();
        },
        add(){
            
            this.guarantinfo.guarantname = '';
            this.guarantinfo.guarantdesc = '';
            this.guarantinfo.id = '';
            this.editor = true;
            //
        },
        submitform(formName){
            console.log("selmerchant");
            console.log(this.selmerchant);
            console.log(JSON.stringify(this.selmerchant));
            this.$refs[formName].validate((valid)=>{
                if(valid){
                    this.axios.post(constant.save_guarant_url,qs.stringify(this.guarantinfo),{
                            headers:{'Content-Type': 'application/x-www-form-urlencoded'},
                            /*params:{
                                ismerchant:this.ismerchant,
                                mid:this.mid,
                                selmerchant:JSON.stringify(this.selmerchant),
                            }*/
                    }).then((response)=>{
                        console.log(response.data);
                        const cur_obj = this;
                        if(response.data == "OK"){
                            cur_obj.editor =false;
                            ElMessage.success({
                                type:'success',
                                message:'操作成功',
                                duration:1000,
                                onClose:function(){
                                    cur_obj.$router.go(0);
                                }
                            });
                        }else{
                            ElMessage.error({
                                type:'error',
                                message:'操作失败',
                                duration:1500,
                            });
                        }
                    })
                }else{
                    return false;
                }
            });
        },
        ChangeLabel(){
            if(this.guarantinfo.status == "0")
                this.statuslabel = '停用';
            if(this.guarantinfo.status == "1")
                this.statuslabel = '启用';
        },
        Getdate(row, column){
            var cur_date = row[column.property];
            console.log(cur_date);
            return  formatDate(cur_date);
            function formatDate(date){
                var datetime = new Date(date*1000);
                var year = datetime.getFullYear();
                var month = ("0" + (datetime.getMonth() + 1)).slice(-2);
                var day = ("0" + datetime.getDate()).slice(-2);
                return year + "-" + month + "-" + day;
            }
        },
        Edit(id){
            //
            
            if(!id)
                return false;
            console.log(id);
            this.axios.get(constant.get_guarant_url,{
                headers:{
                    'Content-Type': 'application/json'
                },
                params:{
                    id:id
                }
            }).then((response)=>{
                console.log(response.data);
                this.editor = true;
                this.guarantinfo.guarantname = response.data.GuarantTitle;
                this.guarantinfo.id = response.data.ID;
                this.guarantinfo.guarantdesc = response.data.GuarantDesc;
                this.guarantinfo.status = response.data.Status;
                
            });

        },
        Enable(id){
            this.axios.get(constant.enable_guarant_url,{
                headers:{
                    'Content-Type': 'application/json'
                },
                params:{
                    id:id
                }
            }).then((response)=>{
                console.log(response);
                if(response.data == "OK"){
                    this.$router.go(0);
                }else{
                    ElMessage.error({
                        type:'error',
                        message: '操作失败'
                    });
                }
            });
            return false;
        },
        Disable(id){
            this.axios.get(constant.disable_guarant_url,{
                headers:{
                    'Content-Type': 'application/json'
                },
                params:{
                    id:id
                }
            }).then((response)=>{
                console.log(response);
                if(response.data == "OK"){
                    this.$router.go(0);
                }else{
                    ElMessage.error({
                        type:'error',
                        message: '操作失败'
                    });
                }
            });
            return false;
        },
        Del(id){
            if(!confirm("此操作将删除该保障服务, 是否继续?"))
                return false;
            this.axios.get(constant.del_guarant_url,{
                headers:{
                    'Content-Type': 'application/json'
                },
                params:{
                    id:id
                }
            }).then((response)=>{
                console.log(response.data);
                const cur_obj = this;
                if(response.data == "OK"){
                    ElMessage.success({
                        type:'success',
                        message:'删除成功',
                        duration:1000,
                        onClose:function(){
                            cur_obj.$router.go(0);
                        }
                    });
                }else if(response.data == "USING"){
                    ElMessage.error({
                        type:'error',
                        message:'删除失败，请停用该保障服务，并确认该保障服务下没有套餐再进行删除操作'
                    });
                }
            });
        },
        init(){
            console.log(this.status);
            this.axios.get(constant.get_guarant_url,{
                headers:{
                    'Content-Type': 'application/json'
                },
                params:{
                    status: this.status,
                    keyword: this.keyword,
                    ismerchant:this.ismerchant,
                    mid:this.mid,
                    selmerchant:JSON.stringify(this.selmerchant),
                }
            }).then((response)=>{
                console.log(response);
                this.tabledata = response.data;
            });
        },
        
        
    },
    created:function(){
        this.ismerchant = Cookies.get("IsMerchant");
        this.mid = Cookies.get("mid");
        this.axios.get(constant.get_guarant_url,{
            headers:{
                'Content-Type': 'application/json'
            },
            params:{
                ismerchant:this.ismerchant,
                mid:this.mid,
            }
        }).then((response)=>{
            console.log(response.data);
            this.tabledata = response.data;
        });

        this.axios.get(constant.allmerchant,{
            headers:{
                "Content-Type":'application/json'
            }
        }).then((response)=>{
            console.log("商户列表");
            console.log(response.data);
            let list = response.data;
            this.merchantlist = [];
            for(let i = 0;i<list.length;i++){
                let obj = new Object();
                obj.label = list[i]['Merchant'];
                obj.key = list[i]['ID'];
                this.merchantlist.push(obj);
            }
            console.log(this.merchantlist);
        });

        this.axios.get(constant.get_cascader_url,{
            headers:{
                'Content-Type': 'application/json'
            },
        }).then((response)=>{
            console.log(response.data);
            this.option = response.data;
        });

        this.axios.get(constant.get_brand_url,{
            headers:{
                'Content-Type': 'application/json'
            },
            params:{
                status: 1
            }
        }).then((response)=>{
            console.log(response.data);
            this.brands = response.data;
        });
    }
}
</script>